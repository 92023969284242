import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Info } from "lucide-react";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../ui/Card";

import CreditCardPayment from "./CreditCard";

import { devPlans } from "../../utlis/plans";
// import { assumptions } from "../../utlis/assumptions";
import { setPhone } from "../../actions/lookup";
import { createPaymentIntent } from "../../actions/stripe";
import { clearTemp } from "../../actions/auth";

// -----------TO DO----------- //
// It creates the billing but not the phone or sign from state.auth.temp

const BillingSetupPage = ({
  user,
  temp,
  setPhone,
  createPaymentIntent,
  intent,
}) => {
  const navigate = useNavigate();

  const plans = devPlans;

  useEffect(() => {
    createPaymentIntent(1500);
  }, [createPaymentIntent]);

  const [secret, setSecret] = useState({ clientSecret: "" });
  useEffect(() => {
    if (intent) {
      setSecret(intent);
    }
  }, [intent]);

  useEffect(() => {
    if (user) {
      if (user.plan) {
        const found = plans.filter((plan) => plan.plan === user.plan);
        if (found.length > 0) {
          if (temp) {
            if (temp.phone) {
              setPhone(temp.phone.number);
            }
          }
        }
      }
    }
  }, [user, plans, temp, setPhone]);

  useEffect(() => {
    if (user) {
      if (user.phone && user.plan !== "free") {
        if (temp) {
          if (temp.sign) {
            navigate("/creating-account");
            clearTemp();
          } else {
            navigate("/dashboard");
            clearTemp();
          }
        }
      }
    }
  }, [navigate, temp, user]);

  const handlePayment = async () => {
    try {
      // Example: Call your backend to create a PaymentIntent
      // const response = await createPaymentIntent(1500);
      // return response.data; // Assumes backend returns { clientSecret: "..." }
    } catch (error) {
      throw new Error("Could not initiate payment. Please try again.");
    }
  };

  const [planSet, setPlanSet] = useState(false);
  useEffect(() => {
    if (user) {
      if (!user.plan) {
        setPlanSet(false);
      } else if (user.plan !== "free") {
        setPlanSet(true);
        // navigate("/dashboard");
      }
    }
  }, [navigate, user]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 p-4">
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">
            Set Up Billing
          </CardTitle>
          <CardDescription className="text-center">
            Add your payment method for your first 5 codes for only $15!
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-6 p-4 bg-blue-50 dark:bg-blue-900 rounded-lg flex items-start">
            <Info className="h-5 w-5 text-blue-500 mr-2 mt-0.5" />
            <p className="text-sm text-blue-600 dark:text-blue-300">
              You'll be charged based on your usage. You can create codes as
              needed, and you'll only pay for what you use. You can upgrade or
              downgrade at any time.
            </p>
          </div>
          <div>
            <CreditCardPayment
              user={user}
              handlePayment={handlePayment}
              secret={secret.clientSecret}
              letBill={!planSet}
            />
            {planSet && (
              <div className="text-center my-3">
                Your plan is already set. No need to set up billing again.{" "}
                <Link to="/dashboard">Go to Dashboard</Link>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  temp: state.auth.temp,
  intent: state.stripe.intent,
});

export default connect(mapStateToProps, { setPhone, createPaymentIntent })(
  BillingSetupPage
);
