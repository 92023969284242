"use client";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "../ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/Accordion";
import { Phone, MessageSquare, Users } from "lucide-react";
import { plans } from "../../utlis/plans";

export default function LandingPage() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState({
    feature1: false,
    feature2: false,
    feature3: false,
  });
  const faqs = [
    {
      id: 1,
      question: "How quickly can I start using SimpleSignCalls?",
      answer:
        "You can start using SimpleSignCalls immediately after signing up. You will choose a local phone number and be able to create codes unique to you.",
    },
    {
      id: 2,
      question: "Do I need special hardware or a new phone number?",
      answer:
        "No special hardware is needed. You choose a dedicated phone number for your SimpleSignCalls service, which prospects will text to receive information.",
    },
    {
      id: 3,
      question: "Can I customize the information sent to prospects?",
      answer:
        "You can customize the property information for each listing in your dashboard. This ensures that prospects receive the most relevant and up-to-date information. It is easy to change so when you move the code, you simply update it with new marketing.",
    },
    {
      id: 4,
      question: "How do I receive leads from SimpleSignCalls?",
      answer:
        "You'll also receive email notifications for new leads, allowing you to follow up quickly. You can integrate this directly into your CRM.",
    },
    {
      id: 5,
      question: "Does SimpleSignCalls Integrate with my CRM?",
      answer:
        "Yes! You can either use Zapier or use your CRM's email-to-add-a-lead function. Simpily forward the email notification directly to your CRM's provided email and it will get added.",
    },
    {
      id: 6,
      question: "How long is the contract?",
      answer: "We don't have a contract. You can start and cancel at any time.",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const feature1Position =
        document.getElementById("feature1")?.offsetTop || 0;
      const feature2Position =
        document.getElementById("feature2")?.offsetTop || 0;
      const feature3Position =
        document.getElementById("feature3")?.offsetTop || 0;

      setIsVisible({
        feature1: scrollPosition > feature1Position,
        feature2: scrollPosition > feature2Position,
        feature3: scrollPosition > feature3Position,
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [selectedCodes, setSelectedCodes] = useState(5);

  const calculatePrice = (number) => {
    const plan = plans.find(
      (plan) => number >= plan.minCodes && number <= plan.codes
    );
    return plan ? plan.price : "Contact Us";
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800">
      <header className="bg-white dark:bg-gray-800 shadow-sm sticky top-0 z-10">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
              <Link to="/">SimpleSignCalls</Link>
            </div>

            <div className="flex space-x-4">
              {/* Login Button */}
              <Button
                variant="outline"
                className="border-blue-600 text-blue-600 hover:bg-blue-50 dark:hover:bg-gray-700"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>

              {/* Sign Up Button */}
              <Button
                className="bg-blue-600 text-white hover:bg-blue-700"
                onClick={() => navigate("/register")}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </nav>
      </header>

      <main>
        <section className="container mx-auto px-6 py-16 text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-gray-800 dark:text-white">
            Simplify Your Real Estate Marketing
          </h1>
          <p className="text-xl mb-8 text-gray-600 dark:text-gray-300">
            Get instant leads with our text-to-info service.
          </p>
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
              Try It Now!
            </h2>
            <p className="mb-4 text-gray-600 dark:text-gray-300">
              Text &apos;DEMO&apos; to
            </p>
            <p className="text-3xl font-bold mb-4 text-blue-600 dark:text-blue-400">
              (385) 290-4140
            </p>
            <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">
              You&apos;ll receive instant property information and see how
              SimpleSignCalls works in action!
            </p>
          </div>
        </section>

        <section
          id="how-it-works"
          className="bg-gray-100 dark:bg-gray-700 py-16"
        >
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">
              How It Works
            </h2>
            <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8">
              <Card className="w-full md:w-1/3">
                <CardHeader>
                  <CardTitle className="text-center">
                    1. Market Your Custom Code
                  </CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <Phone className="h-16 w-16 mx-auto mb-4 text-blue-600 dark:text-blue-400" />
                  <p>
                    Place your custom code on any marketing or for sale sign.
                  </p>
                </CardContent>
              </Card>
              <Card className="w-full md:w-1/3">
                <CardHeader>
                  <CardTitle className="text-center">
                    2. Prospect Texts Code
                  </CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <MessageSquare className="h-16 w-16 mx-auto mb-4 text-green-600 dark:text-green-400" />
                  <p>
                    Interested buyers text the code to your dedicated number.
                  </p>
                </CardContent>
              </Card>
              <Card className="w-full md:w-1/3">
                <CardHeader>
                  <CardTitle className="text-center">
                    3. Instant Information
                  </CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                  <Users className="h-16 w-16 mx-auto mb-4 text-purple-600 dark:text-purple-400" />
                  <p>
                    They receive a text instantly, and you get their contact
                    info.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        <section id="features" className="container mx-auto px-6 py-16">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">
            Features
          </h2>

          <div id="feature1" className="mb-16">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{
                opacity: isVisible.feature1 ? 1 : 0,
                y: isVisible.feature1 ? 0 : 50,
              }}
              transition={{ duration: 0.5 }}
              className="flex flex-col md:flex-row items-center"
            >
              <div className="md:w-1/2 mb-6 md:mb-0">
                <Phone className="h-32 w-32 text-blue-600 dark:text-blue-400 mx-auto" />
              </div>
              <div className="md:w-1/2">
                <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
                  24/7 Automated Responses
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  Never miss a lead. Our system responds instantly to inquiries
                  at any time of day or night.
                </p>
              </div>
            </motion.div>
          </div>

          <div id="feature2" className="mb-16">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{
                opacity: isVisible.feature2 ? 1 : 0,
                y: isVisible.feature2 ? 0 : 50,
              }}
              transition={{ duration: 0.5 }}
              className="flex flex-col md:flex-row-reverse items-center"
            >
              <div className="md:w-1/2 mb-6 md:mb-0">
                <MessageSquare className="h-32 w-32 text-green-600 dark:text-green-400 mx-auto" />
              </div>
              <div className="md:w-1/2">
                <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
                  Customizable Property Info
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  Tailor the information sent to prospects for each property,
                  ensuring they get the most relevant details. Update the
                  response with a few clicks.
                </p>
              </div>
            </motion.div>
          </div>

          <div id="feature3">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{
                opacity: isVisible.feature3 ? 1 : 0,
                y: isVisible.feature3 ? 0 : 50,
              }}
              transition={{ duration: 0.5 }}
              className="flex flex-col md:flex-row items-center"
            >
              <div className="md:w-1/2 mb-6 md:mb-0">
                <Users className="h-32 w-32 text-purple-600 dark:text-purple-400 mx-auto" />
              </div>
              <div className="md:w-1/2">
                <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
                  Lead Notifications
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  Get instant notifications when a lead text or call. Integrate
                  with your CRM.
                </p>
              </div>
            </motion.div>
          </div>
        </section>
        <section id="pricing" className="bg-gray-100 dark:bg-gray-700 py-16">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">
              Custom Pricing
            </h2>
            <div className="max-w-lg mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
              <p className="text-center text-gray-600 dark:text-gray-300 mb-6">
                Choose the number of codes you need, and we'll tailor the
                pricing for your business.
              </p>
              <div className="flex items-center justify-between mb-4">
                <span className="text-sm text-gray-600 dark:text-gray-300">
                  1 Code
                </span>
                <span className="text-sm text-gray-600 dark:text-gray-300">
                  100+ Codes
                </span>
              </div>
              <input
                type="range"
                min="1"
                max="101"
                value={selectedCodes}
                onChange={(e) => setSelectedCodes(e.target.value)}
                className="w-full accent-blue-600"
              />
              <div className="text-center my-4">
                <h3 className="text-2xl font-bold text-gray-800 dark:text-white">
                  {calculatePrice(selectedCodes) === "Contact Us" ? (
                    <>100 Or More</>
                  ) : (
                    <> {selectedCodes} Codes</>
                  )}
                </h3>
                {calculatePrice(selectedCodes) === "Contact Us" ? (
                  <Link
                    to="/contact-us"
                    className="text-lg text-blue-600 dark:text-blue-400 font-semibold"
                  >
                    Contact Us
                  </Link>
                ) : (
                  <p className="text-lg text-blue-600 dark:text-blue-400 font-semibold">
                    ${calculatePrice(selectedCodes)}/mo
                  </p>
                )}
              </div>
              <Button
                className="w-full bg-blue-600 hover:bg-blue-700 text-white mt-4"
                onClick={() => navigate(`/register`)}
              >
                Get Started
              </Button>
            </div>
          </div>
        </section>

        <section id="faq" className="container mx-auto px-6 py-16">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-white">
            Frequently Asked Questions
          </h2>
          <Accordion
            type="single"
            collapsible
            className="w-full max-w-2xl mx-auto"
          >
            {faqs.map((item, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger>{item.question}</AccordionTrigger>
                <AccordionContent>{item.answer}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </section>

        <section className="bg-blue-600 dark:bg-blue-800 py-16">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold mb-4 text-white">
              Ready to Simplify Your Real Estate Marketing?
            </h2>
            {/* <p className="text-xl mb-8 text-blue-100">Get started today.</p> */}
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
              {/* <Input
                placeholder="Enter your email"
                className="w-full md:w-64 bg-white dark:bg-gray-700"
              /> */}
              <Button
                size="lg"
                className="w-full md:w-auto bg-white text-blue-600 hover:bg-blue-50"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Get Started
              </Button>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-100 dark:bg-gray-800 py-8">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-600 dark:text-gray-300 mb-4 md:mb-0 text-sm">
              © 2024 SimpleSignCalls.
              <br /> All rights reserved.
            </div>
            <div className="flex space-x-4 text-sm">
              <Link
                to="/"
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Home
              </Link>
              <Link
                to="/privacy"
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms"
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Terms of Service
              </Link>
              <Link
                to="/contact-us"
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
