import axios from "axios";
import {
  FIND_NUMBERS,
  SET_NUMBER,
  NUMBERS_ERROR,
  UPDATE_TEMP,
  LOADING_NUMBERS,
} from "../actions/types";
import { setAlert } from "./alert";
import { loadUser } from "./auth";

//Get all signs by user
export const getPhones = (area) => async (dispatch) => {
  try {
    const res = await axios.get("/api/users/phone/" + area);

    dispatch({
      type: LOADING_NUMBERS,
    });
    dispatch({
      type: FIND_NUMBERS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: NUMBERS_ERROR,
      payload: error,
    });
  }
};

export const setTempPhone = (number) => async (dispatch) => {
  const phone = { phone: { number } };

  console.log(phone);
  dispatch({
    type: UPDATE_TEMP,
    payload: phone,
  });
};

export const setPhone = (number) => async (dispatch) => {
  console.log(number);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ number });
  try {
    const res = await axios.post(
      "/api/users/phone/assign/" + number,
      body,
      config
    );

    dispatch({
      type: SET_NUMBER,
      payload: res.data,
    });
    dispatch(setAlert("Phone number set!", "success"));
    dispatch(loadUser());
  } catch (error) {
    dispatch({
      type: NUMBERS_ERROR,
      payload: error,
    });
  }
};
