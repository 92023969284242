export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const GET_ALERTS = "GET_ALERTS";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const MANUAL_REGISTER = "MANUAL_REGISTER";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const MANUAL_REGISTER_FAIL = "MANUAL_REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const UPDATE_TEMP = "UPDATE_TEMP";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SEND_RESET_PASSWORD = "SEND_RESET_PASSWORD";
export const LOGOUT = "LOGOUT";

export const GET_SIGNS = "GET_SIGNS";
export const GET_SIGN = "GET_SIGN";
export const UPDATE_SIGN = "UPDATE_SIGN";
export const CREATE_SIGN = "CREATE_SIGN";
export const UPDATE_SIGN_ERROR = "UPDATE_SIGN_ERROR";
export const SIGN_ERROR = "SIGN_ERROR";
export const CLEAR_SIGNS = "CLEAR_SIGNS";
export const CLEAR_SINGLE_SIGN = "CLEAR_SINGLE_SIGN";
export const DELETE_SIGN = "DELETE_SIGN";
export const SAVING_SIGNS = "SAVING_SIGN";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGE = "GET_MESSAGE";
export const LOADING_MESSAGES = "LOADING_MESSAGES";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const CLEAR_SINGLE_MESSAGE = "CLEAR_SINGLE_MESSAGE";
export const MESSAGES_ERROR = "MESSAGES_ERROR";
export const ADD_FAKE = "ADD_FAKE";

export const GET_STATS = "GET_STATS";
export const CLEAR_STATS = "CLEAR_STATS";
export const STATS_ERROR = "STATS_ERROR";
export const FIND_NUMBERS = "FIND_NUMBERS";
export const LOADING_NUMBERS = "LOADING_NUMBERS";
export const SET_NUMBER = "SET_NUMBER";
export const CLEAR_LOOKUP = "CLEAR_LOOKUP";
export const NUMBERS_ERROR = "NUMBERS_ERROR";

export const GET_SETTINGS = "GET_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const CLEAR_SETTINGS = "CLEAR_SETTINGS";
export const CLEAR_TEMP = "CLEAR_TEMP";
export const SEND_ERROR = "SEND_ERROR";
export const SET_PLAN = "SET_PLAN";
export const CHANGE_PLAN_NEEDED = "CHANGE_PLAN_NEEDED";
export const CANCEL_PLAN = "CANCEL_PLAN";
export const SET_STRIPE_INTENT = "SET_STRIPE_INTENT";
export const PLAN_FAIL = "PLAN_FAIL";
export const CLEAR_STRIPE = "CLEAR_STRIPE";
export const SET_SEARCH = "SET_SEARCH";
export const SEARCH_ERROR = "SEARCH_ERROR";

export const GET_ADMIN_USER = "GET_ADMIN_USER";
export const GET_ADMIN_SINGLE_USER = "GET_ADMIN_SINGLE_USER";
export const GET_ADMIN_STATS = "GET_ADMIN_STATS";
export const ADMIN_ERROR = "ADMIN_ERROR";
export const CLEAR_ADMIN = "CLEAR_ADMIN";
export const ADMIN_LOADING = "ADMIN_LOADING";
export const ADMIN_CANCEL_USER = "ADMIN_CANCEL_USER";
