import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, Link, Navigate, useParams } from "react-router-dom";
import { Button } from "../ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { Phone, MessageSquare, ArrowLeft } from "lucide-react";
import AuthMenu from "../layout/AuthMenu";
import { getMessages } from "../../actions/messages";
import { setAlert } from "../../actions/alert";
import { prettyPhone } from "../../utlis/pretty";
import { timestampToDate } from "../../utlis/helpers";

// -----------TO DO----------- //
// What if it is a call instead of a text? How would I show that in the conversation section

const LeadDetails = ({ messages, getMessages, setAlert }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  // Function to handle the redirect
  const handleRedirect = (path) => {
    navigate(path);
  };

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const [lead, setLead] = useState({
    _id: 1,
    timestamp: "2023-05-15 10:30 AM",
    from: "555-555-5555",
    caller: "John Doe",
    code: "123MAIN",
    messsage: "123MAIN",
    forward: "555-555-5555",
    address: "tracking",
    textSent: "Hi, I'm interested in the property at 123 Main St.",
    type: "text",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLeadById = (array, id) => {
    const found = array.find((item) => item._id === id);
    if (found) {
      return found;
    } else {
      setAlert("Code not found, try again", "danger", 5000);
      return <Navigate to="/codes" />;
    }
  };

  useEffect(() => {
    if (messages) {
      setLead(getLeadById(messages, id));
    }
  }, [messages, id, getLeadById]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />

      <main className="container mx-auto p-4 space-y-6">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
            onClick={() => handleRedirect("/leads")}
          >
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to Leads
          </Button>
          <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-200">
            Lead Details
          </h2>
        </div>

        <div className="grid gap-6 md:grid-cols-2">
          <Card className="bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300">
            <CardHeader>
              <CardTitle className="text-xl font-bold text-gray-800 dark:text-gray-200">
                Lead Information
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-center space-x-4">
                  <div>
                    <h3 className="text-lg font-semibold">{lead.caller}</h3>
                    {/* <p className="text-sm text-gray-500 dark:text-gray-400">
                      {lead.email}
                    </p> */}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Phone
                    </p>
                    <p className="mt-1">{prettyPhone(lead.from)}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Date
                    </p>
                    <p className="mt-1">{timestampToDate(lead.timestamp)}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Type
                    </p>
                    <p className="mt-1 flex items-center">
                      {lead.type === "call" ? (
                        <>
                          <Phone className="h-4 w-4 text-green-500 mr-1" /> Call
                        </>
                      ) : (
                        <>
                          <MessageSquare className="h-4 w-4 text-blue-500 mr-1" />{" "}
                          Text
                        </>
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Code
                    </p>
                    <Link to={`/codes/${lead.code}`}>
                      <p className="mt-1">{lead.code}</p>
                    </Link>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300">
            <CardHeader>
              <CardTitle className="text-xl font-bold text-gray-800 dark:text-gray-200">
                {/* Conversation */}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4 max-h-[400px] overflow-y-auto">
                <div className={`flex justify-start`}>
                  <div
                    className={`max-w-[70%] rounded-lg p-3 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200`}
                  >
                    <p className="text-sm">
                      {lead.type === "text"
                        ? lead.message
                        : `Call Forwarded to ${prettyPhone(lead.forward)}`}
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                      {timestampToDate(lead.timestamp)}
                    </p>
                  </div>
                </div>

                <div className={`flex justify-end`}>
                  {lead.type === "text" && (
                    <div
                      className={`max-w-[70%] rounded-lg p-3 bg-blue-500 text-white`}
                    >
                      <p className="text-sm">{lead.textSent}</p>
                      <p className="text-xs text-gray-200 dark:text-gray-400 mt-1">
                        {timestampToDate(lead.timestamp)}
                      </p>
                    </div>
                  )}
                </div>
                {lead.notify && (
                  <div className={`flex justify-start`}>
                    <>
                      <div
                        className={`max-w-[70%] rounded-lg p-3 bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200`}
                      >
                        <p className="text-sm">
                          <b>Notifcation Sent to:</b>
                          <br />
                          {lead.notify.map((email, index) => (
                            <div key={index}>
                              <span key={index}>
                                {email}
                                {index < lead.notify.length - 1 && <br />}
                              </span>
                            </div>
                          ))}
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                          {timestampToDate(lead.timestamp)}
                        </p>
                      </div>
                    </>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  messages: state.message.messages,
  codes: state.sign.signs,
});

export default connect(mapStateToProps, { getMessages, setAlert })(LeadDetails);
