import { useState } from "react";
import { connect } from "react-redux";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../ui/Card";
import { Alert, AlertDescription } from "../ui/Alert";
import { Link } from "react-router-dom";
import { ArrowLeft, Mail } from "lucide-react";
import { setAlert } from "../../actions/alert";
import { sendReset } from "../../actions/auth";

const ForgotPasswordPage = ({ setAlert, sendReset }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setAlert("Please enter your email address");
      return;
    }
    sendReset({ email });
    setSuccess(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <div className="flex items-center">
            <Link
              href="/login"
              className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
            </Link>
            <CardTitle className="text-2xl font-bold">Reset Password</CardTitle>
          </div>
          <CardDescription>
            Enter your email to receive a password reset link
          </CardDescription>
        </CardHeader>
        <CardContent>
          {!success ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <Button type="submit" className="w-full">
                <Mail className="mr-2 h-4 w-4" /> Send Reset Link
              </Button>
            </form>
          ) : (
            <Alert className="bg-green-100 border-green-400 text-green-700">
              <AlertDescription>
                If an account exists for {email}, you will receive a password
                reset email shortly.
              </AlertDescription>
            </Alert>
          )}
          <div className="mt-4 text-center text-sm">
            Remember your password?{" "}
            <Link href="/login" className="text-blue-600 hover:underline">
              Log in
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setAlert, sendReset })(
  ForgotPasswordPage
);
