import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  MANUAL_REGISTER,
  MANUAL_REGISTER_FAIL,
  USER_LOADED,
  UPDATE_TEMP,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  SEND_RESET_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER,
  USER_UPDATE_FAIL,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  loading: true,
  reset: false,
  user: null,
  temp: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
    case UPDATE_USER:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        reset: false,
        user: payload,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        reset: true,
        //loading: false
      };
    case REGISTER_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        reset: true,
        loading: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        ...payload,
        reset: true,
        loading: false,
      };
    case MANUAL_REGISTER:
      return {
        ...state,
        // ...payload,
        // isAuthenticated: true,
        // reset: true,
        // loading: false,
      };
    case UPDATE_TEMP:
      return {
        ...state,
        temp: payload,
      };
    case SEND_RESET_PASSWORD:
      return {
        ...state,
        isAuthenicated: false,
        loading: false,
        reset: true,
        user: null,
      };
    case USER_UPDATE_FAIL:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        reset: false,
        user: payload,
      };
    case MANUAL_REGISTER_FAIL:
      return {
        ...state,
      };
    case LOGOUT:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        temp: null,
      };
    default:
      return state;
  }
}
