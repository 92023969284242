import React, { useState, useEffect } from "react";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";

const NotificationEmails = ({ initialEmails, onEmailsChange }) => {
  const [emails, setEmails] = useState(initialEmails);

  const isValidEmail = (email) => {
    return email && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  // Calculate which fields should be visible and reorganize emails if needed
  const getVisibleFields = () => {
    const allFields = ["notify", "notify2", "notify3", "notify4", "notify5"];

    // Collect all non-empty emails
    const filledEmails = allFields
      .map((field) => emails[field])
      .filter((email) => email && email.trim() !== "");

    // Redistribute emails to remove gaps
    const updatedEmails = {
      notify: emails.notify || "", // Always keep primary email as-is
    };

    // Redistribute additional emails
    filledEmails
      .filter((email) => email !== emails.notify) // Skip primary email
      .forEach((email, index) => {
        updatedEmails[`notify${index + 2}`] = email;
      });

    // Update emails state if there were gaps
    if (JSON.stringify(emails) !== JSON.stringify(updatedEmails)) {
      setEmails(updatedEmails);
      onEmailsChange(updatedEmails);
    }

    // Calculate visible fields
    const lastFilledIndex = allFields.findIndex(
      (field) => !updatedEmails[field]
    );
    return allFields.slice(0, Math.min(lastFilledIndex + 1, 5));
  };

  const [visibleFields, setVisibleFields] = useState(getVisibleFields());

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedEmails = { ...emails, [name]: value };
    setEmails(updatedEmails);
    onEmailsChange(updatedEmails);
  };

  // Update visible fields when emails change
  useEffect(() => {
    setVisibleFields(getVisibleFields());
  }, [emails]);

  // Initial setup when component mounts
  useEffect(() => {
    setEmails(initialEmails);
    setVisibleFields(getVisibleFields());
  }, [initialEmails]);

  return (
    <div className="space-y-4">
      {/* Primary Notify Email */}
      <div className="space-t-2">
        <Label htmlFor="notify">Email Notifications</Label>
        <Input
          id="notify"
          name="notify"
          type="email"
          value={emails.notify || ""}
          onChange={handleInputChange}
          placeholder="Primary email"
        />
      </div>

      {/* Additional Notify Emails */}
      {visibleFields
        .filter((field) => field !== "notify")
        .map((field) => (
          <div key={field} className="space-t-2">
            {/* <Label htmlFor={field}>Additional Notification Email</Label> */}
            <Input
              id={field}
              name={field}
              type="email"
              value={emails[field] || ""}
              onChange={handleInputChange}
              placeholder="Enter email"
            />
          </div>
        ))}
    </div>
  );
};

export default NotificationEmails;
