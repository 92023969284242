import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const GoogleAnalytics = () => {
  let { pathname } = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-0KVDE9G0SD", {});
    // ReactGA.initialize([{ trackingId: "G-47V8YJT5XX" }]);
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);
  return null;
};

export default GoogleAnalytics;
