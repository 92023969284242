import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowLeft, Lock } from "lucide-react";

import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../ui/Card";
import { Alert, AlertDescription } from "../ui/Alert";
import { setAlert } from "../../actions/alert";
import { updatePassword } from "../../actions/auth";

const ResetPasswordPage = ({ setAlert, updatePassword, reset }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tokenFromUrl = searchParams.get("token");
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [searchParams]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      setAlert("Please fill out all fields");
      return;
    }
    if (password !== confirmPassword) {
      setAlert("Passwords do not match");
      return;
    }
    updatePassword({ password, token });
  };

  useEffect(() => {
    if (reset) {
      setSuccess(true);
    }
  }, [reset]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <div className="flex items-center">
            <Link
              to="/login"
              className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
            </Link>
            <CardTitle className="text-2xl font-bold">
              Set New Password
            </CardTitle>
          </div>
          <CardDescription>
            Enter a new password for your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          {!success ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="password">New Password</Label>
                <Input
                  id="password"
                  type="password"
                  placeholder="Enter a new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="confirm-password">Confirm New Password</Label>
                <Input
                  id="confirm-password"
                  type="password"
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <Button type="submit" className="w-full">
                <Lock className="mr-2 h-4 w-4" /> Reset Password
              </Button>
            </form>
          ) : (
            <Alert className="bg-green-100 border-green-400 text-green-700">
              <AlertDescription>
                Your password has been successfully reset. You can now{" "}
                <Link to="/login" className="text-blue-600 hover:underline">
                  log in
                </Link>
                .
              </AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => ({
  reset: state.auth.reset,
});

export default connect(mapStateToProps, { setAlert, updatePassword })(
  ResetPasswordPage
);
