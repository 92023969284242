import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";

import { clearSingleSign, getSigns } from "../../actions/sign";

import { Search, Plus } from "lucide-react";
import AuthMenu from "../layout/AuthMenu";

const Code = ({ signs, messages, getSigns, clearSingleSign }) => {
  const navigate = useNavigate();
  const handleRedirect = (path) => {
    navigate(path);
  };

  useEffect(() => {
    getSigns();
    clearSingleSign();
  }, [getSigns, clearSingleSign]);

  const countLeadsPerCode = (codes, leads) => {
    if (codes && leads) {
      // Initialize an object to store the counts
      const leadCounts = {};

      // Iterate through each lead and count the occurrences of each code
      leads.forEach((lead) => {
        const code = lead.code; // Each lead has a code field

        // Increment the count for this code in the leadCounts object
        leadCounts[code] = (leadCounts[code] || 0) + 1;
      });

      // Map the codes array to include lead counts for each code
      return codes.map((code) => ({
        ...code,
        lead_count: leadCounts[code.code] || 0, // Default to 0 if there are no leads for this code
      }));
    } else {
      return [
        {
          id: 1,
          code: "LOADING....",
          address: "Loading your codes",
          message: "Loading...",
          lead_count: 0,
        },
      ];
    }
  };

  const [codes, setCodes] = useState([
    {
      id: 1,
      code: "LOADING....",
      address: "Loading your codes",
      message: "Loading...",
      lead_count: 0,
    },
  ]);

  useEffect(() => {
    if (signs && messages) {
      const codesToSet = countLeadsPerCode(signs, messages);
      setCodes(codesToSet);
    }
  }, [signs, messages]);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredCodes = codes.filter(
    (code) =>
      code.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      code.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const limitCharacters = (text, maxLength) => {
    if (text) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
    }
    return text;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />

      <main className="container mx-auto p-4 space-y-6">
        <Card className="bg-white dark:bg-gray-800">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-gray-800 dark:text-gray-200 flex justify-between items-center">
              <span>Your Codes</span>
              <div className="relative w-64">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
                <Input
                  type="text"
                  placeholder="Filter codes..."
                  className="pl-8 px-4 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div>
                <Button
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                  onClick={() => handleRedirect("/codes/new")}
                >
                  <Plus className="mr-2 h-4 w-4" /> New Code
                </Button>
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            {filteredCodes.length === 0 ? (
              <div
                onClick={() => handleRedirect("/codes/new")}
                className="flex flex-col items-center justify-center bg-gradient-to-br from-blue-200 to-blue-400 dark:from-gray-700 dark:to-gray-900 text-white rounded-lg shadow-lg p-8 hover:scale-105 transition-transform duration-200 cursor-pointer"
              >
                <h2 className="text-2xl font-bold mb-4">Create a Code</h2>
                {codes.length === 0 ? (
                  <p className="text-center mb-6">
                    You don’t have any codes yet. Click here to create your
                    first one and start tracking leads!
                  </p>
                ) : (
                  <p className="text-center mb-6">
                    We didn't find that code. Click here to create it!
                  </p>
                )}
                <Button className="bg-blue-600 hover:bg-blue-700 text-white">
                  <Plus className="mr-2 h-4 w-4" /> Create Code
                </Button>
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Code</TableHead>
                    <TableHead>Tracking</TableHead>
                    <TableHead>Message</TableHead>
                    <TableHead>Leads</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredCodes.map((code) => (
                    <TableRow
                      key={code._id}
                      onClick={() => handleRedirect(`/codes/${code._id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell className="font-medium">{code.code}</TableCell>
                      <TableCell>{code.address}</TableCell>
                      <TableCell>{limitCharacters(code.message, 55)}</TableCell>
                      <TableCell>{code.lead_count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

Code.propTypes = {
  getSigns: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  signs: state.sign.signs,
  messages: state.message.messages,
});

export default connect(mapStateToProps, { getSigns, clearSingleSign })(Code);
