import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, Navigate, Link } from "react-router-dom";
import {
  Save,
  ArrowLeft,
  Phone,
  MessageSquare,
  Trash2,
  Loader2,
  AlertTriangle,
} from "lucide-react";

import { updateSign, deleteSign } from "../../actions/sign";
import { setAlert } from "../../actions/alert";

import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Textarea } from "../ui/TextArea";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { Label } from "../ui/Label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/Dialog";

import AuthMenu from "../layout/AuthMenu";
import { timestampToDate } from "../../utlis/helpers";
import { plans } from "../../utlis/plans";
import { updatePlan } from "../../actions/stripe";
import { prettyPhone } from "../../utlis/pretty";
import NotificationEmails from "./NotificationEmails";

const CodeDetails = ({
  codes,
  leads,
  user,
  userPlan,
  updateSign,
  deleteSign,
  saving,
  setAlert,
  updatePlan,
}) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const handleRedirect = (path) => {
    navigate(path);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const findLeadsByCode = (array, code) => {
    return array.filter((item) => item.code === code);
  };

  const [code, setCode] = useState({
    id: 1,
    code: "",
    message: "",
    forward: "555-555-5555",
    trackingCode: "",
    // notify: "agent@example.com",
    // notify2: "",
    // notify3: "",
    // notify4: "",
    // notify5: "",
  });
  const [emails, setEmails] = useState({
    notify: "",
    notify2: "",
    notify3: "",
    notify4: "",
    notify5: "",
  });

  const [recentLeads, setRecentLeads] = useState([
    {
      id: 1,
      name: "Loading",
      type: "text",
      date: "2023-05-15",
      phone: "555-987-6543",
    },
  ]);

  const [error, setError] = useState(false);
  //set the code
  useEffect(() => {
    const found = codes.find((code) => code._id === id);
    if (found) {
      setCode(found);
      const foundEmail = {
        notify: found.notify || "",
        notify2: found.notify2 || "",
        notify3: found.notify3 || "",
        notify4: found.notify4 || "",
        notify5: found.notify5 || "",
      };
      setEmails(foundEmail);
    } else {
      setAlert("Could not find the code. Try again.", "danger");
      setError(true);
    }
  }, [codes, id, setAlert]);

  //set the leads
  useEffect(() => {
    if (code) {
      setRecentLeads(findLeadsByCode(leads, code.code));
    }
  }, [leads, code]);

  //add error if a plan isn't set
  const [noPlan, setNoPlan] = useState(false);
  useEffect(() => {
    if (user) {
      if (user.plan === "free" || !user.plan) {
        setNoPlan(true);
      }
    }
  }, [user]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCode((prevCode) => ({
      ...prevCode,
      [name]: value,
    }));
  };

  const handleEmailsChange = (updatedEmails) => {
    setEmails(updatedEmails);
  };

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setCode({ ...code, forward: phone });
  };

  const [submitted, setSubmitted] = useState(true);
  const handleSave = (e) => {
    e.preventDefault();
    // setSubmitted(true);
    if (!code.message || code.message.length < 5 || code.message.length > 480) {
      setAlert("Message should be between 5 and 480 characters", "error");
    } else {
      updateSign({
        id: code._id,
        message: code.message,
        notify: emails.notify,
        notify2: emails.notify2,
        notify3: emails.notify3,
        notify4: emails.notify4,
        notify5: emails.notify5,
        forward: code.forward,
        address: code.address,
      });
    }
  };
  useEffect(() => {
    setSubmitted(saving);
  }, [saving]);

  const checkDowngrade = (currentPlan, codes, plans) => {
    const planInfo = plans.find((plan) => plan.plan === currentPlan);
    if (planInfo.minCodes > codes) {
      const downgradePlan = plans.find(
        (plan) =>
          plan.codes >= codes &&
          plan.minCodes <= codes &&
          plan.time === planInfo.time
      );
      return downgradePlan ? downgradePlan.plan : null;
    }
    return null;
  };

  const [deleted, setDeleted] = useState(false);
  const handleDelete = () => {
    const canDowngrade = checkDowngrade(userPlan, codes.length - 1, plans);
    if (canDowngrade) {
      updatePlan(canDowngrade);
    }
    deleteSign(id);
    setDeleted(true);
  };

  if (deleted || error) {
    return <Navigate to="/codes" />;
  }
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />

      <main className="container mx-auto p-4 space-y-6">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
            onClick={() => handleRedirect("/codes")}
          >
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to Codes
          </Button>
          <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-200">
            Edit Code: {code.code}
          </h2>
        </div>

        <Card className="bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-gray-800 dark:text-gray-200">
              Code Details
            </CardTitle>
            {noPlan && (
              <div className="mt-2 p-4 bg-yellow-100 text-yellow-800 rounded-lg border border-yellow-300 flex">
                <AlertTriangle />
                <p className="text-sm font-medium">
                  Your account doesn't have a plan set.{" "}
                  <Link to="/billing" style={{ textDecoration: "underline" }}>
                    Set up your billing
                  </Link>{" "}
                  for this code to work.
                </p>
              </div>
            )}
          </CardHeader>
          <CardContent>
            <form className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="message">Response Message</Label>
                <Textarea
                  id="message"
                  name="message"
                  value={code.message}
                  onChange={handleInputChange}
                  className="w-full h-32"
                  placeholder="Enter the message to be sent when this code is used"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="forward">Forward Phone Number</Label>
                <Input
                  type="tel"
                  id="forward"
                  name="forward"
                  value={code.forward}
                  onChange={formatPhone}
                  placeholder="Enter the phone number for call forwarding"
                />
              </div>

              <NotificationEmails
                initialEmails={emails}
                onEmailsChange={handleEmailsChange}
              />

              <div className="space-y-2">
                <Label htmlFor="trackingCode">Tracking Code</Label>
                <Input
                  type="text"
                  id="trackingCode"
                  name="trackingCode"
                  value={code.trackingCode}
                  onChange={handleInputChange}
                  placeholder="Enter a tracking code (e.g., property address)"
                />
              </div>
              {submitted ? (
                <Button
                  onClick={handleSave}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                  disabled
                >
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Saving
                </Button>
              ) : (
                <Button
                  onClick={handleSave}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                >
                  <Save className="mr-2 h-4 w-4" /> Save Changes
                </Button>
              )}
            </form>
          </CardContent>
        </Card>

        <Card className="bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-gray-800 dark:text-gray-200">
              Recent Leads
            </CardTitle>
          </CardHeader>
          <CardContent>
            {recentLeads.length === 0 ? (
              <div className="space-y-4">
                <p>No Leads Yet</p>
              </div>
            ) : (
              <div className="space-y-4">
                {recentLeads.map((lead) => (
                  <div
                    key={lead._id}
                    className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-blue-50 dark:hover:bg-gray-600 transition-colors duration-150 group"
                    onClick={() => handleRedirect(`/leads/${lead._id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="space-y-1">
                      <p className="text-sm font-medium text-gray-800 dark:text-gray-200">
                        {lead.caller}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {lead.from && prettyPhone(lead.from)} •{" "}
                        {lead.timestamp && timestampToDate(lead.timestamp)}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      {lead.type === "call" ? (
                        <Phone className="h-4 w-4 text-green-500" />
                      ) : (
                        <MessageSquare className="h-4 w-4 text-blue-500" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </CardContent>
        </Card>

        <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
          <DialogTrigger asChild>
            <Button variant="destructive" className="w-full">
              <Trash2 className="mr-2 h-4 w-4" /> Delete Code
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Delete Code</DialogTitle>
              <DialogDescription>
                Are you sure you want to delete this code? If the code it text
                or called it will not work.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleDelete}>
                Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  codes: state.sign.signs,
  leads: state.message.messages,
  user: state.auth.user,
  saving: state.sign.saving,
  userPlan: state.auth.user.plan,
});

export default connect(mapStateToProps, {
  deleteSign,
  updateSign,
  setAlert,
  updatePlan,
})(CodeDetails);
