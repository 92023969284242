import {
  FIND_NUMBERS,
  NUMBERS_ERROR,
  CLEAR_LOOKUP,
  LOADING_NUMBERS,
} from "../actions/types";

const initialState = {
  numbers: [],
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_NUMBERS:
      return { ...state, loading: true };
    case FIND_NUMBERS:
      return { ...state, numbers: payload, loading: false };
    case CLEAR_LOOKUP:
      return { ...state, numbers: [], loading: false };
    case NUMBERS_ERROR:
      return { ...state, signs: [], loading: false };
    default:
      return state;
  }
}
