import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Input } from "../ui/Input";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";
import {
  Phone,
  MessageSquare,
  ChevronUp,
  ChevronDown,
  Search,
} from "lucide-react";
import AuthMenu from "../layout/AuthMenu";

import { getMessages } from "../../actions/messages";

import { timestampToDate } from "../../utlis/helpers";
import { prettyPhone } from "../../utlis/pretty";

// -----------TO DO----------- //
// Search bar to find the lead by phone number

const LeadsPage = ({ messages, getMessages }) => {
  const navigate = useNavigate();
  const handleRedirect = (path) => {
    navigate(path);
  };

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const [leads, setLeads] = useState([
    {
      id: 1,
      caller: "John Doe",
      from: "555-123-4567",
      timestamp: "2023-05-15",
      type: "text",
      code: "HOUSE123",
    },
  ]);
  useEffect(() => {
    if (messages) {
      setLeads(messages);
    }
  }, [messages, setLeads]);

  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // const [showSearchResults, setShowSearchResults] = useState(false);
  const filterPhoneNumbers = (leads, searchTerm) => {
    // Normalize the search term by removing non-numeric characters
    const normalizedSearchTerm = searchTerm.replace(/\D/g, "");

    return leads.filter((lead) => {
      // Normalize the phone number in the lead
      const normalizedPhone = lead.from.replace(/\D/g, "");
      // Check if the normalized phone number includes the normalized search term
      return normalizedPhone.includes(normalizedSearchTerm);
    });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (term.length > 0) {
      const results = filterPhoneNumbers(leads, term);
      setSearchResults(results);
      // setShowSearchResults(true);
    } else {
      setSearchResults([]);
      // setShowSearchResults(false);
    }
  };

  const sortedLeads = useMemo(() => {
    let sortableLeads = [...leads];
    if (sortConfig.key) {
      sortableLeads.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableLeads;
  }, [leads, sortConfig]);

  const filteredLeads = useMemo(() => {
    return sortedLeads.filter((lead) =>
      lead.from.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedLeads, searchTerm]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <ChevronUp className="h-4 w-4" />
      ) : (
        <ChevronDown className="h-4 w-4" />
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />

      <main className="container mx-auto p-4 space-y-6">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
          All Leads
        </h2>

        <div className="flex justify-between items-center mb-4">
          <div className="relative w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              type="text"
              placeholder="Search phone numbers"
              className="pl-8 px-4 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {/* {showSearchResults && (
              <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border rounded-md shadow-lg">
                {searchResults.map((result) => (
                  <div
                    key={result._id}
                    className="p-2 hover:bg-blue-50 dark:hover:bg-gray-600 cursor-pointer transition-colors duration-150"
                    onClick={() => handleRedirect(`/leads/${result._id}`)}
                  >
                    <div className="font-medium text-blue-600 dark:text-blue-400">
                      {result.from}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-300">
                      {result.name}
                    </div>
                  </div>
                ))}
              </div>
            )} */}
          </div>
        </div>

        <Card className="bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-gray-800 dark:text-gray-200">
              Leads
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead
                    className="cursor-pointer"
                    onClick={() => requestSort("date")}
                  >
                    Date {getSortIcon("date")}
                  </TableHead>
                  <TableHead
                    className="cursor-pointer"
                    onClick={() => requestSort("name")}
                  >
                    Name {getSortIcon("name")}
                  </TableHead>
                  <TableHead
                    className="cursor-pointer"
                    onClick={() => requestSort("phone")}
                  >
                    Phone {getSortIcon("phone")}
                  </TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Code</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredLeads.map((lead) => (
                  <TableRow
                    key={lead.id}
                    onClick={() => handleRedirect(`/leads/${lead._id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{timestampToDate(lead.timestamp)}</TableCell>
                    <TableCell>{lead.caller}</TableCell>
                    <TableCell>{prettyPhone(lead.from)}</TableCell>
                    <TableCell>
                      {lead.type === "call" ? (
                        <Phone className="h-4 w-4 text-green-500" />
                      ) : (
                        <MessageSquare className="h-4 w-4 text-blue-500" />
                      )}
                    </TableCell>
                    <TableCell>{lead.code}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

LeadsPage.propTypes = {
  getMessages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  messages: state.message.messages,
});

export default connect(mapStateToProps, { getMessages })(LeadsPage);
