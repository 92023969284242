import axios from "axios";
import { SET_PLAN, PLAN_FAIL, CANCEL_PLAN, SET_STRIPE_INTENT } from "./types";
import { setAlert } from "./alert";
import { loadUser } from "./auth";

export const setPlan = (stripeToken, plan, email) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ stripeToken, email, plan });
    const res = await axios.post("/api/stripe/", body, config);

    dispatch({
      type: SET_PLAN,
      payload: res.data,
    });
    dispatch(loadUser());
    dispatch(setAlert("Plan set!", "success"));
  } catch (err) {
    const error = err.response.data.errors;
    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PLAN_FAIL,
      payload: error,
    });
  }
};

export const updatePlan = (plan) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ plan });
    const res = await axios.post("/api/stripe/upgrade", body, config);

    dispatch({
      type: SET_PLAN,
      payload: res.data,
    });
    dispatch(setAlert("Plan updated!", "success"));
    dispatch(loadUser());
  } catch (err) {
    const error = err.response.data.errors;
    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PLAN_FAIL,
      payload: error,
    });
  }
};

export const cancelPlan = () => async (dispatch) => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = { cancel: true };
  try {
    const res = await axios.post("/api/stripe/cancel", body, config);
    dispatch({
      type: CANCEL_PLAN,
      payload: res.data,
    });
    dispatch(loadUser());
    dispatch(setAlert("Your plan has been cancelled", "success"));
  } catch (err) {
    const error = err.response.data.errors;
    if (error) {
      error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PLAN_FAIL,
      payload: error,
    });
  }
};

export const updateCard =
  (paymentMethodId, email, paymentMethod) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ paymentMethodId, email, paymentMethod });
      const res = await axios.post("/api/stripe/card", body, config);

      dispatch({
        type: SET_PLAN,
        payload: res.data,
      });
      dispatch(loadUser());
      dispatch(setAlert("Card updated!", "success"));
    } catch (err) {
      const error = err.response.data.errors;
      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: PLAN_FAIL,
        payload: error,
      });
    }
  };

export const createPaymentIntent = (amount) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ amount });
    const res = await axios.post(
      "/api/stripe/create-payment-intent",
      body,
      config
    );
    dispatch({
      type: SET_STRIPE_INTENT,
      payload: res.data,
    });
  } catch (err) {
    // console.log(err);
    // const error = err.response.data.errors;
    // if (error) {
    //   error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    // }
    dispatch(setAlert(err.response.data, "danger"));
    dispatch({
      type: PLAN_FAIL,
      payload: err.response.data,
    });
  }
};

export const createSubscription =
  (paymentMethodId, priceId, paymentMethod) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({ paymentMethodId, priceId, paymentMethod });
      const res = await axios.post(
        "/api/stripe/create-subscription",
        body,
        config
      );

      dispatch({
        type: SET_PLAN,
        payload: res.data, // Contains subscription and client secret
      });

      dispatch(loadUser());
      dispatch(setAlert("Subscription created successfully!", "success"));
    } catch (err) {
      const error = err.response?.data?.errors || [
        { msg: "Failed to create subscription" },
      ];
      error.forEach((err) => dispatch(setAlert(err.msg, "danger")));
      dispatch({
        type: PLAN_FAIL,
        payload: error,
      });
    }
  };
