import axios from "axios";
import { GET_SETTINGS, SEND_ERROR, SEND_FEEDBACK } from "./types";
import { setAlert } from "./alert";

export const getSettings = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: GET_SETTINGS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const sendFeedback =
  ({ email, subject, message }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ email, subject, message });
    try {
      const res = await axios.post("/api/users/feedback", body, config);

      dispatch({
        type: SEND_FEEDBACK,
        payload: res.data,
      });
      dispatch(setAlert("Message Sent!", "success"));
    } catch (err) {
      console.log(err);
      const error = err.response.data.errors;

      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: SEND_ERROR,
        payload: { msg: "Error" },
      });
    }
  };
