const messageCount = (messages, type) => {
  if (messages.length > 0) {
    const filtered = messages.filter((m) => m.type === type);
    return filtered.length;
  } else {
    return 0;
  }
};

const signCount = (signs) => {
  if (signs.length > 0) {
    return signs.length;
  } else {
    return 0;
  }
};

const findSign = (signs, id) => {
  if (signs.length > 0) {
    const sign = signs.filter((sign) => sign._id === id);
    return sign[0];
  } else {
    return null;
  }
};
const findSignByCode = (signs, code) => {
  if (signs.length > 0) {
    const sign = signs.filter((sign) => sign.code === code);
    if (sign.length > 0) {
      return sign[0];
    }
  }
  return null;
};
const findMessage = (messages, id) => {
  if (messages.length > 0) {
    const message = messages.filter((m) => m._id === id);
    return message[0];
  } else {
    return null;
  }
};

const messagesBySign = (messages, code) => {
  if (messages.length > 0) {
    return messages.filter((m) => m.code === code);
  } else {
    return [];
  }
};

const getMessages = (messages, type) => {
  if (type) {
    return messages.filter((m) => m.type === type);
  } else {
    return messages;
  }
};

const timestampToDate = (dateString) => {
  const date = new Date(dateString);

  // Format the day, month, year, hour, and minute components
  const day = String(date.getDate()).padStart(2, "0"); // DD
  const month = date.toLocaleString("en-US", { month: "short" }); // MMM
  const year = date.getFullYear(); // YYYY
  const hours = String(date.getHours()).padStart(2, "0"); // H (24-hour format)
  const minutes = String(date.getMinutes()).padStart(2, "0"); // MM

  return `${day} ${month} ${year} ${hours}:${minutes}`;
};

export {
  messageCount,
  signCount,
  findSign,
  findSignByCode,
  messagesBySign,
  findMessage,
  getMessages,
  timestampToDate,
};
