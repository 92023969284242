import axios from "axios";
import { setAlert, clearAlerts } from "./alert";
import { getAllUsers } from "./admin";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_PASSWORD,
  SEND_RESET_PASSWORD,
  LOGOUT,
  CLEAR_SIGNS,
  CLEAR_MESSAGES,
  UPDATE_USER,
  USER_UPDATE_FAIL,
  CLEAR_STATS,
  CLEAR_LOOKUP,
  CLEAR_STRIPE,
  MANUAL_REGISTER,
  MANUAL_REGISTER_FAIL,
  UPDATE_TEMP,
} from "./types";
import setAuthToken from "../utlis/setAuthToken";
import { getMessages } from "./messages";
import { getSigns } from "./sign";

//Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/auth");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    dispatch(getSigns());
    dispatch(getMessages());
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//Login User
export const login = (email, password) => async (dispatch) => {
  dispatch(clearAlerts());

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    if (err.response) {
      const error = err.response.data.errors;

      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
      }
    } else {
      dispatch(setAlert("Error", "danger", 5000));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const clearTemp = () => (dispatch) => {
  dispatch({ type: UPDATE_TEMP, payload: null });
};

//Logout User
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_SIGNS });
  dispatch({ type: CLEAR_MESSAGES });
  dispatch({ type: CLEAR_STATS });
  dispatch({ type: CLEAR_LOOKUP });
  dispatch({ type: CLEAR_STRIPE });
  dispatch({ type: LOGOUT });
};

//Register User
export const register =
  ({ name, email, password, phone, registerSourcePage }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      name,
      email,
      password,
      phone,
      registerSourcePage,
    });

    try {
      const res = await axios.post("/api/users", body, config);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());
    } catch (err) {
      const error = err.response.data.errors;

      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

//Register User
export const adminRegister =
  ({ email, phone }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
      phone,
    });

    try {
      const res = await axios.post("/api/users/manual", body, config);

      dispatch({
        type: MANUAL_REGISTER,
        payload: res.data,
      });

      dispatch(setAlert("User created!", "success", 5000));
      // dispatch(getSignById(id));
      dispatch(getAllUsers());

      // dispatch(loadUser());
    } catch (err) {
      const error = err.response.data.errors;

      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: MANUAL_REGISTER_FAIL,
      });
    }
  };

//Send Reset Password
export const sendReset =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email });

    try {
      const res = await axios.post("/api/users/reset", body, config);

      setAlert("Password reset email sent", "sucess");
      dispatch({
        type: SEND_RESET_PASSWORD,
        payload: res.data,
      });
      dispatch(logout());
    } catch (err) {
      const error = err.response.data.errors;
      // console.log(err.response);
      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };
//Send Reset Password
export const updatePassword =
  ({ password, token }) =>
  async (dispatch) => {
    dispatch(logout());
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ password });
    const link = "/api/users/reset/" + token;

    try {
      const res = await axios.post(link, body, config);

      dispatch({
        type: RESET_PASSWORD,
        payload: res.data,
      });
      dispatch(setAlert("Your password has been reset!", "success"));
      // dispatch(loadUser());
    } catch (err) {
      const error = err.response.data.errors;
      // console.log(err.response.data);
      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

export const updateUser =
  ({ email, forwardPhone, timeZone, brokerage, notification, callScript }) =>
  async (dispatch) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email,
      forwardPhone,
      timeZone,
      brokerage,
      notification,
      callScript,
    });

    try {
      const res = await axios.post("api/auth/update", body, config);

      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      dispatch(setAlert("Updated!", "success"));
      dispatch(loadUser());
    } catch (err) {
      // console.log(err);
      const error = err.response.data.errors;
      // console.log(err.response.data);
      if (error) {
        error.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: USER_UPDATE_FAIL,
      });
    }
  };
