import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  User,
  Settings,
  Mail,
  CreditCardIcon,
  KeyRound,
  Contact,
} from "lucide-react";

import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";

import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/Tabs";
import { Textarea } from "../ui/TextArea";

import { updateUser } from "../../actions/auth";
import { sendReset } from "../../actions/auth";
import { setAlert } from "../../actions/alert";

import AuthMenu from "../layout/AuthMenu";
import BillingSettings from "./BillingSettings";
import ContactUs from "./ContactUs";

const SettingsPage = ({ user, updateUser, sendReset }) => {
  const [profile, setProfile] = useState({
    email: "",
    forwardPhone: "",
    timeZone: "Eastern",
    callScript: "",
    callInMessage: "",
    notification: "",
  });

  useEffect(() => {
    if (user) {
      setProfile({
        email: user.email,
        forwardPhone: user.forwardPhone,
        timeZone: user.timeZone,
        callScript: user.callScript,
        callInMessage: user.callInMessage,
        notification: user.notification,
      });
    }
  }, [user]);

  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setProfile({ ...profile, forwardPhone: phone });
  };

  const handleProfileChange = (nameOrEvent, valueOrNull) => {
    if (typeof nameOrEvent === "string") {
      // This is for the Select component
      setProfile((prevProfile) => ({
        ...prevProfile,
        [nameOrEvent]: valueOrNull,
      }));
    } else {
      // This is for standard input elements
      const { name, value } = nameOrEvent.target;
      setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
    }
  };

  const onSubmitProfile = async (e) => {
    e.preventDefault();
    updateUser({
      email: profile.email,
      forwardPhone: profile.forwardPhone,
      timeZone: profile.timeZone,
      notification: profile.notification,
      callScript: profile.callScript,
    });
  };

  const onSubmitPassword = (e) => {
    e.preventDefault();
    sendReset({ email: profile.email });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />

      <main className="container mx-auto p-4 space-y-6">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
          Settings
        </h2>

        <Tabs defaultValue="profile" className="space-y-4">
          <TabsList>
            <TabsTrigger value="profile">
              <User /> Profile
            </TabsTrigger>
            <TabsTrigger value="billing">
              <CreditCardIcon />
              Billing
            </TabsTrigger>
            <TabsTrigger value="password">
              <KeyRound />
              Password
            </TabsTrigger>
            <TabsTrigger value="contact-us">
              <Contact />
              Contact Us
            </TabsTrigger>
          </TabsList>

          <TabsContent value="profile">
            <Card>
              <CardHeader>
                <CardTitle>Profile Settings</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                {/* Email Field */}
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    value={profile.email}
                    onChange={handleProfileChange}
                  />
                </div>

                {/* Phone Field */}
                <div className="space-y-2">
                  <Label htmlFor="forwardPhone">Phone</Label>
                  <Input
                    id="forwardPhone"
                    name="forwardPhone"
                    type="tel"
                    placeholder="555-123-4567"
                    value={profile.forwardPhone}
                    onChange={formatPhone}
                  />
                </div>

                {/* TimeZone Field */}
                <div className="space-y-2">
                  <Label htmlFor="timeZone">TimeZone</Label>
                  <Select
                    id="timeZone"
                    name="timeZone"
                    // className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                    value={profile.timeZone}
                    onValueChange={(value) =>
                      handleProfileChange("timeZone", value)
                    }
                  >
                    <SelectTrigger className="">
                      <SelectValue placeholder={profile.timeZone} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Pacific">Pacific</SelectItem>
                      <SelectItem value="Mountain">Mountain</SelectItem>
                      <SelectItem value="Mountain (AZ)">
                        Mountain (AZ)
                      </SelectItem>
                      <SelectItem value="Central">Central</SelectItem>
                      <SelectItem value="Eastern">Eastern</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {/* Call Script Field */}
                <div className="space-y-2">
                  <Label htmlFor="callScript">Call Script</Label>
                  <Textarea
                    id="callScript"
                    name="callScript"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                    rows="4"
                    placeholder="Enter the call script to be read when someone calls."
                    value={profile.callScript}
                    onChange={handleProfileChange}
                  ></Textarea>
                </div>

                {/* Email Notification Field */}
                <div className="space-y-2">
                  <Label htmlFor="notification">Email Notification</Label>
                  <Textarea
                    id="notification"
                    name="notification"
                    className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200"
                    rows="4"
                    placeholder="Enter the email notification message for leads."
                    value={profile.notification}
                    onChange={handleProfileChange}
                  ></Textarea>
                  <p className="text-sm">
                    Enter {"{{caller}}"}, {"{{phone}}"},{"{{code}}"},
                    {"{{tracking}}"}, and {"{{type}}"} that will be replaced
                    dynamically when you get a lead.
                  </p>
                </div>

                {/* Save Button */}
                <Button onClick={onSubmitProfile} className="w-full">
                  Save Profile
                </Button>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="billing">
            <BillingSettings />
          </TabsContent>

          <TabsContent value="password">
            <Card>
              <CardHeader>
                <CardTitle>Password Settings</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <p>
                  Get a password reset email sent to you so you can set a new
                  password.
                </p>
                <Button onClick={onSubmitPassword} className="w-full">
                  <Mail className="mr-2 h-4 w-4" /> Send Reset Link
                </Button>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="contact-us">
            <Card>
              <ContactUs />
            </Card>
          </TabsContent>
        </Tabs>
      </main>
    </div>
  );
};

Settings.propTypes = {
  updateUser: PropTypes.func.isRequired,
  sendReset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  updateUser,
  sendReset,
  setAlert,
})(SettingsPage);
