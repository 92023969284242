"use client";

import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Search, Loader2 } from "lucide-react";
import { connect } from "react-redux";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { Label } from "./ui/Label";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";

import { getPhones, setTempPhone, setPhone } from "../actions/lookup";
import { setAlert } from "../actions/alert";

const SelectPhoneNumberPage = ({
  getPhones,
  lookup,
  temp,
  setTempPhone,
  phoneLoad,
  user,
  setPhone,
}) => {
  const [areaCode, setAreaCode] = useState("");
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const [nonFound, setNonFound] = useState(false);
  const searchNumbers = async (code) => {
    if (code.length === 3) {
      await getPhones(code);
    }
  };

  useEffect(() => {
    setAvailableNumbers(lookup);
  }, [lookup]);

  useEffect(() => {
    if (areaCode.length === 3 && availableNumbers.length === 0) {
      setNonFound(true);
    } else {
      setNonFound(false);
    }
  }, [areaCode, availableNumbers]);

  const handleAreaCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 3);
    setAreaCode(value);
    searchNumbers(value);
  };

  const handleNumberSelect = (number) => {
    setSelectedNumber(number);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedNumber) {
      setAlert("Please select a phone number");
      return;
    } else {
      if (user.plan !== "free") {
        setPhone(selectedNumber);
      } else {
        setTempPhone(selectedNumber);
      }
    }
  };

  useEffect(() => {
    if (user) {
      if (user.phone) {
        return <Navigate to="/dashboard" />;
      }
    }
  });

  if (user) {
    if (user.phone) {
      // setAlert("Phone is already set", "warning");
      return <Navigate to="/dashboard" />;
    }
  }

  if (temp) {
    if (temp.phone) {
      setAlert(
        "You got it! Now, let's create a code to start marketing",
        "success"
      );
      return <Navigate to="/first-code" />;
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 flex items-center justify-center">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl font-bold text-center">
            Choose Your Phone Number
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-4">
              <Label htmlFor="areaCode">Enter Your Area Code</Label>
              <div className="relative">
                <Input
                  id="areaCode"
                  value={areaCode}
                  onChange={handleAreaCodeChange}
                  placeholder="Enter area code"
                  className="pl-10 y-3 px-5"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
            </div>
            {availableNumbers.length > 0 && !phoneLoad ? (
              <>
                <div className="space-y-2">
                  <Label>Available Numbers</Label>
                  <div className="grid grid-cols-2 gap-2">
                    {availableNumbers.map((number) => (
                      <Button
                        key={number.phoneNumber}
                        type="button"
                        variant={
                          selectedNumber === number.phoneNumber
                            ? "default"
                            : "outline"
                        }
                        className="w-full"
                        onClick={() => handleNumberSelect(number.phoneNumber)}
                      >
                        {number.friendlyName}
                      </Button>
                    ))}
                    <Button
                      className="w-full"
                      type="button"
                      variant={
                        selectedNumber === `+1${areaCode}5555555`
                          ? "default"
                          : "outline"
                      }
                      onClick={() => handleNumberSelect(`+1${areaCode}5555555`)}
                    >
                      FAKE
                    </Button>
                  </div>
                  <Button type="submit" className="w-full">
                    Select Number
                  </Button>
                </div>
              </>
            ) : (
              <>
                {nonFound && (
                  <div>
                    {phoneLoad ? (
                      <div className="py-3 flex items-center justify-center">
                        <div>
                          <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                        </div>
                      </div>
                    ) : (
                      <h3>No numbers found, try another area code.</h3>
                    )}
                  </div>
                )}
              </>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lookup: state.lookup.numbers,
  phoneLoad: state.lookup.loading,
  temp: state.auth.temp,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getPhones, setTempPhone, setPhone })(
  SelectPhoneNumberPage
);
