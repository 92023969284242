"use client";

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { sendFeedback } from "../../actions/settings";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Textarea } from "../ui/TextArea";
import { Label } from "../ui/Label";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../ui/Card";
import { setAlert } from "../../actions/alert";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA component

const ContactUsPage = ({ sendFeedback, setAlert, auth }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const { name, email, subject, message } = formData;
  // const [success, setSuccess] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false); // State for reCAPTCHA validation

  const isLoggedIn = auth?.isAuthenticated;

  // Automatically attach logged-in user info
  useEffect(() => {
    if (isLoggedIn) {
      setFormData((prevData) => ({
        ...prevData,
        name: auth.user?.name || "",
        email: auth.user?.email || "",
      }));
    }
  }, [isLoggedIn, auth.user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValid(!!value); // Update captcha validation status
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isLoggedIn && (!captchaValid || !isValidEmail(email))) {
      setAlert(
        "Please complete the reCAPTCHA and ensure valid inputs.",
        "danger"
      );
      return;
    }

    if (subject.length > 0 && message.length > 3) {
      const feedbackData = isLoggedIn
        ? { subject, message, email: auth.user.email }
        : { name, email, subject, message };

      sendFeedback(feedbackData);
      // setAlert(
      //   "Your message has been sent, we'll get back to you soon",
      //   "success"
      // );
      setFormData({ name: "", email: "", subject: "", message: "" });
      // setSuccess(true);
    } else {
      setAlert("Make sure all fields are filled out correctly.", "danger");
    }
  };

  return (
    <Card className="w-full ">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl font-bold text-center">
          Contact Us
        </CardTitle>
        <CardDescription className="text-center">
          We'd love to hear from you. Send us a message!
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Name and Email Fields (only for non-logged-in users) */}
          {!isLoggedIn && (
            <>
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Your email"
                  required
                />
              </div>
            </>
          )}
          <div className="space-y-2">
            <Label htmlFor="subject">Subject</Label>
            <Input
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              placeholder="Subject of your message"
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="message">Message</Label>
            <Textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Your message"
              className="min-h-[100px]"
              required
            />
          </div>

          {/* reCAPTCHA (only for non-logged-in users) */}
          {!isLoggedIn && (
            <div className="space-y-2">
              <ReCAPTCHA
                sitekey="6LesgZQqAAAAANVLPr9VRRuCp64sI2iDFhGB_KdQ" // Replace with your actual site key
                onChange={handleCaptchaChange}
              />
            </div>
          )}

          <Button type="submit" className="w-full">
            Send Message
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { sendFeedback, setAlert })(
  ContactUsPage
);
