import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
// import ReactGA from "react-ga4";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";

import { setAlert } from "../../actions/alert";
import { plans } from "../../utlis/plans";
import { createPaymentIntent, createSubscription } from "../../actions/stripe";
import { Loader2 } from "lucide-react";

// Initialize Stripe with your public key
const stripePromise = loadStripe("pk_test_Khx7hkeU1SvJXF80fxthA55g");

const PaymentForm = ({
  user,
  alert,
  secret,
  createSubscription,
  plan,
  letBill,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      setAlert("Stripe has not loaded yet.", "danger");
      return;
    }
    setWaiting(true);
    setLoading(true);

    try {
      if (!secret) {
        throw new Error("Client secret is missing.");
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: user.email,
        },
      });

      createSubscription(paymentMethod.id, plans[0].plan, paymentMethod.card);

      if (error) {
        setAlert(
          error.message || "An error occurred during payment.",
          "danger"
        );
      }
    } catch (err) {
      setAlert(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (alert) {
      setWaiting(false);
    }
  }, [alert]);

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#32325d",
              "::placeholder": {
                color: "#a0aec0",
              },
            },
            invalid: {
              color: "#fa755a",
            },
          },
        }}
      />
      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:opacity-50"
        disabled={!stripe || loading || waiting || !secret || !letBill}
      >
        {loading || waiting ? (
          <>
            <Loader2 className="mx-auto animate-spin" />
          </>
        ) : (
          "Get Started"
        )}
      </button>
    </form>
  );
};

// Wrapper component for Stripe Elements
const CreditCardPayment = ({
  user,
  handlePayment,
  secret,
  createSubscription,
  letBill,
}) => (
  <div className=" bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 p-4">
    <Elements stripe={stripePromise}>
      <PaymentForm
        user={user}
        handlePayment={handlePayment}
        secret={secret}
        createSubscription={createSubscription}
        letBill={letBill}
        // createPaymentIntent={createPaymentIntent}
      />
    </Elements>
  </div>
);

// Map state and actions if needed
const mapStateToProps = (state) => ({
  user: state.auth.user,
  plan: state.stripe.plan,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  createPaymentIntent,
  createSubscription,
})(CreditCardPayment);
