import {
  SET_PLAN,
  CHANGE_PLAN_NEEDED,
  CANCEL_PLAN,
  SET_STRIPE_INTENT,
  PLAN_FAIL,
  CLEAR_STRIPE,
} from "../actions/types";

const initialState = {
  plan: null,
  loading: true,
  needsAttention: false,
  intent: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PLAN:
      return {
        ...state,
        plan: payload,
        loading: false,
        needsAttention: false,
      };
    case CLEAR_STRIPE:
      return {
        ...state,
        plan: null,
        loading: false,
        needsAttention: false,
      };
    case CHANGE_PLAN_NEEDED:
      return {
        ...state,
        plan: payload,
        loading: false,
        needsAttention: true,
      };
    case SET_STRIPE_INTENT:
      return {
        ...state,
        intent: payload,
      };
    case CANCEL_PLAN:
    case PLAN_FAIL:
      return {
        ...state,
        plan: null,
        loading: false,
        needsAttention: false,
      };
    default:
      return state;
  }
}
