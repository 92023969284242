"use client";

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";

import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Textarea } from "../ui/TextArea";
import { Label } from "../ui/Label";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/Tooltip";
import { Info } from "lucide-react";
import { setAlert } from "../../actions/alert";
import { createTempSign } from "../../actions/sign";

// -----------TO DO----------- //

const FirstCode = ({ setAlert, createTempSign, temp, codes }) => {
  const navigate = useNavigate();

  // Function to handle the redirect
  const handleRedirect = (path) => {
    navigate(path);
  };

  const [code, setCode] = useState({
    code: "",
    message: "",
    forwardNumber: "",
    emailNotification: "",
    trackingCode: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCode((prevCode) => ({
      ...prevCode,
      [name]: value,
    }));
  };
  const formatPhone = (e) => {
    let phone;
    const input = e.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);
    if (input.length > 6) {
      phone = `(${zip}) ${middle}-${last}`;
    } else if (input.length > 3) {
      phone = `(${zip}) ${middle}`;
    } else if (input.length > 0) {
      phone = `(${zip}`;
    }
    setCode({ ...code, forwardNumber: phone });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!code.code || !code.message || code.forwardNumber.length !== 14) {
      setAlert("Code and Message are required fields");
      return;
    }
    createTempSign(
      code.code,
      code.message,
      code.emailNotification,
      code.forwardNumber,
      code.trackingCode,
      temp
    );
  };

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (temp) {
      if (temp.sign) {
        setRedirect(true);
      }
    }
  }, [temp]);

  //go to dashboard if codes are already set up
  useEffect(() => {
    if (codes) {
      if (codes.length > 0) {
        handleRedirect("/dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codes]);

  if (redirect) {
    return <Navigate to="/billing" />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 flex items-center justify-center">
      <div className="absolute top-4 right-4">
        <Button variant="outline" onClick={() => handleRedirect("/billing")}>
          Skip
        </Button>
      </div>
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">
            Create Your First Code
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="code" className="flex items-center">
                Code
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4 ml-2 text-blue-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        This is the unique identifier for your property or
                        campaign. Use something memorable like "MAINST" or
                        "BEACHHOUSE". If you use only numbers, it can be entered
                        when called as well as text.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Input
                id="code"
                name="code"
                value={code.code}
                onChange={handleInputChange}
                placeholder="MAINST"
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="message" className="flex items-center">
                Response Message
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4 ml-2 text-blue-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        This message will be auto sent when someone texts this
                        code. Include property details or call-to-action, or
                        even another code to keep them texting.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Textarea
                id="message"
                name="message"
                value={code.message}
                onChange={handleInputChange}
                placeholder="Thank you for your interest in 123 Main St. A representative will contact you shortly."
                className="min-h-[100px]"
                required
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="forwardNumber" className="flex items-center">
                Forward Phone Number
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4 ml-2 text-blue-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Calls to this code will be forwarded to this number.
                        Code must be only numbers for this to function.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Input
                id="forwardNumber"
                name="forwardNumber"
                value={code.forwardNumber}
                onChange={formatPhone}
                placeholder="(555) 123-4567"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="emailNotification" className="flex items-center">
                Email Notification
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4 ml-2 text-blue-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        You'll receive an email at this address when someone
                        uses your code.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Input
                id="emailNotification"
                name="emailNotification"
                type="email"
                value={code.emailNotification}
                onChange={handleInputChange}
                placeholder="agent@example.com"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="trackingCode" className="flex items-center">
                Tracking Code
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="h-4 w-4 ml-2 text-blue-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Optional: Use this to track specific campaigns or
                        properties in your reports. You can reuse the codes, so
                        this is a good way to know where the code was when you
                        received the lead.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Label>
              <Input
                id="trackingCode"
                name="trackingCode"
                value={code.trackingCode}
                onChange={handleInputChange}
                placeholder="123MainSt"
              />
            </div>

            <Button type="submit" className="w-full" onClick={handleSubmit}>
              Create Code
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  temp: state.auth.temp,
  codes: state.sign.signs,
});

export default connect(mapStateToProps, { setAlert, createTempSign })(
  FirstCode
);
