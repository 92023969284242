import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Loader2 } from "lucide-react";
import { createSign } from "../../actions/sign"; // Import the action for creating a sign

const SetUpFirstCode = ({ temp, createSign, codes }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!temp) {
      navigate("/dashboard");
      return;
    }
    createSign({
      code: temp.sign.code,
      message: temp.sign.message,
      notify: temp.sign.notify,
      forward: temp.sign.forward,
      address: temp.sign.address,
    });
  }, [createSign, navigate, temp]);

  useEffect(() => {
    if (codes.length > 0) {
      navigate("/dashboard");
    }
  }, [codes, navigate]);

  const [timeout, setTimeOut] = useState(false);
  useEffect(() => {
    // Set a timeout to trigger after 6 seconds
    const timer = setTimeout(() => {
      setTimeOut(true);
    }, 6000);

    // Cleanup the timeout when the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <Loader2 className="mb-4 animate-spin" />
      <h2 className="text-xl font-bold text-gray-800 dark:text-gray-200">
        Setting up your account...
      </h2>
      <p className="text-sm text-gray-600 dark:text-gray-400">
        Please wait while we configure your first code.
      </p>
      {timeout && (
        <p
          className="mt-3"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/dashboard")}
        >
          This seems to be taking too long... Click here if you aren't
          redirected.
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  temp: state.auth.temp,
  codes: state.sign.signs,
});

export default connect(mapStateToProps, { createSign })(SetUpFirstCode);
