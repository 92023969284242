import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";

import {
  Search,
  Plus,
  Phone,
  MessageSquare,
  ArrowUpRight,
  QrCode,
} from "lucide-react";

import AuthMenu from "./layout/AuthMenu";

import { messageCount, signCount, timestampToDate } from "../utlis/helpers";

const Dashboard = ({ messages, signs, auth: { user, loading } }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const navigate = useNavigate();
  const handleRedirect = (path) => {
    navigate(path);
  };

  const [stats, setStats] = useState({
    totalCodes: 0,
    totalLeads: 0,
  });
  useEffect(() => {
    const msgCount = messageCount(messages, "text");
    const callCount = messageCount(messages, "call");
    const totalLeads = msgCount + callCount;
    const totalCodes = signCount(signs);
    setStats({
      totalLeads,
      totalCodes,
    });
  }, [messages, signs]);

  const [recentLeads, setRecentLeads] = useState([
    {
      id: 1,
      caller: "Loading...",
      type: "text",
      timestamp: "2023-05-15",
      code: "123",
    },
  ]);
  useEffect(() => {
    setRecentLeads(messages);
  }, [messages]);

  const [codes, setCodes] = useState([]);
  useEffect(() => {
    setCodes(signs);
  }, [signs]);

  const filterCodes = (codes, searchTerm) => {
    return codes.filter((code) =>
      code.code.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (term.length > 0) {
      const results = filterCodes(codes, term);
      setSearchResults(results);
      setShowSearchResults(true);
    } else {
      setSearchResults([]);
      setShowSearchResults(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
      <AuthMenu />

      <main className="container mx-auto p-4">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
          Dashboard
        </h2>
        {!loading && (
          <>
            {user && (
              <>
                {!user.phone && (
                  <div
                    className="bg-gradient-to-br from-red-500 to-pink-500 dark:from-red-600 dark:to-pink-600 text-white rounded-lg shadow-lg p-6 mb-6 flex items-center justify-between transform transition-all duration-300 hover:scale-105 hover:shadow-xl cursor-pointer"
                    onClick={() => handleRedirect("/phone")}
                  >
                    <div>
                      <h2 className="text-2xl font-bold mb-2">
                        Set Up Your Phone Number
                      </h2>
                      <p className="text-sm">
                        You haven't set up a phone number yet. Click here to
                        configure it and start receiving calls and leads!
                      </p>
                    </div>
                    <div className="flex items-center">
                      <Phone className="h-10 w-10 text-white mr-4" />
                      <ArrowUpRight className="h-6 w-6 text-white opacity-75" />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-2 mb-8">
          {/* Total Codes */}
          <div
            onClick={() => handleRedirect("/codes")}
            className="bg-gradient-to-br from-blue-500 to-blue-600 dark:from-blue-600 dark:to-blue-700 text-white rounded-lg shadow-lg p-6 flex flex-col justify-between transform transition-all duration-300 hover:scale-105 hover:shadow-xl cursor-pointer"
          >
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-lg font-semibold">Total Codes</h3>
                <p className="text-sm opacity-80">
                  Track and manage your codes
                </p>
              </div>
              <QrCode className="h-8 w-8 opacity-75" />
            </div>
            <div className="text-5xl font-bold">{stats.totalCodes}</div>
            <Button
              className="mt-4 bg-blue-700 hover:bg-blue-800 text-white"
              onClick={() => handleRedirect("/codes")}
            >
              View Codes
            </Button>
          </div>

          {/* Total Leads */}
          <div
            onClick={() => handleRedirect("/leads")}
            className="bg-gradient-to-br from-green-500 to-green-600 dark:from-green-600 dark:to-green-700 text-white rounded-lg shadow-lg p-6 flex flex-col justify-between transform transition-all duration-300 hover:scale-105 hover:shadow-xl cursor-pointer"
          >
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-lg font-semibold">Total Leads</h3>
                <p className="text-sm opacity-80">See all your leads here</p>
              </div>
              <MessageSquare className="h-8 w-8 opacity-75" />
            </div>
            <div className="text-5xl font-bold">{stats.totalLeads}</div>
            <Button
              className="mt-4 bg-green-700 hover:bg-green-800 text-white"
              onClick={() => handleRedirect("/leads")}
            >
              View Leads
            </Button>
          </div>
        </div>

        <div className="flex justify-between items-center mb-6">
          <div className="relative w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              type="text"
              placeholder="Search codes"
              className="pl-8 px-4 bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {showSearchResults && (
              <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-700 border rounded-md shadow-lg">
                {searchResults.map((result) => (
                  <div
                    key={result._id}
                    className="p-2 hover:bg-blue-50 dark:hover:bg-gray-600 cursor-pointer transition-colors duration-150"
                    onClick={() => handleRedirect(`/codes/${result._id}`)}
                  >
                    <div className="font-medium text-blue-600 dark:text-blue-400">
                      {result.code}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-300">
                      {result.description}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Button
            className="bg-blue-600 hover:bg-blue-700 text-white"
            onClick={() => handleRedirect("/codes/new")}
          >
            <Plus className="mr-2 h-4 w-4" /> Create New Code
          </Button>
        </div>

        <Card className="bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300">
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle className="text-xl font-bold text-gray-800 dark:text-gray-200">
              Recent Leads
            </CardTitle>
            <Button
              variant="outline"
              size="sm"
              className="text-blue-600 border-blue-600 hover:bg-blue-50 dark:text-blue-400 dark:border-blue-400 dark:hover:bg-gray-700"
              onClick={() => handleRedirect("/leads")}
            >
              View All Leads
            </Button>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {recentLeads.length > 0 ? (
                <>
                  {recentLeads.map((lead) => (
                    <div
                      key={lead._id}
                      className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-blue-50 dark:hover:bg-gray-600 transition-colors duration-150 group"
                      onClick={() => handleRedirect(`/leads/${lead._id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="space-y-1">
                        <p className="text-sm font-medium text-gray-800 dark:text-gray-200">
                          {lead.caller}
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          {lead.type === "call" ? "Called" : "Texted"} on{" "}
                          {timestampToDate(lead.timestamp)}
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm font-medium text-blue-600 dark:text-blue-400">
                          {lead.code}
                        </span>
                        {lead.type === "call" ? (
                          <Phone className="h-4 w-4 text-blue-500" />
                        ) : (
                          <MessageSquare className="h-4 w-4 text-blue-500" />
                        )}
                        <ArrowUpRight className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-150" />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div>
                  <p>No Leads Yet</p>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  messages: state.message.messages,
  signs: state.sign.signs,
  auth: state.auth,
});

export default connect(mapStateToProps)(Dashboard);
