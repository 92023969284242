import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";

const BlankPage = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800">
      <header className="bg-white dark:bg-gray-800 shadow-sm sticky top-0 z-10">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
              <Link to="/">SimpleSignCalls</Link>
            </div>
            <div className="flex space-x-4">
              {/* Login Button */}
              <Button
                variant="outline"
                className="border-blue-600 text-blue-600 hover:bg-blue-50 dark:hover:bg-gray-700"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>

              {/* Sign Up Button */}
              <Button
                className="bg-blue-600 text-white hover:bg-blue-700"
                onClick={() => navigate("/register")}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </nav>
      </header>
      <main className="container mx-auto px-6 py-10">{children}</main>
      <footer className="bg-gray-100 dark:bg-gray-800 py-8">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-600 dark:text-gray-300 mb-4 md:mb-0 text-sm">
              © 2024 SimpleSignCalls. <br />
              All rights reserved.
            </div>
            <div className="flex space-x-4 text-sm">
              <Link
                to="/"
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Home
              </Link>
              <Link
                to="/privacy"
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms"
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Terms of Service
              </Link>
              <Link
                to="/contact-us"
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BlankPage;
