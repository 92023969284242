import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { loadStripe } from "@stripe/stripe-js";
import { Ban, EditIcon, Loader2 } from "lucide-react";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/Dialog";
import { Button } from "../ui/Button";

import { plans } from "../../utlis/plans";
import { setAlert } from "../../actions/alert";
import {
  cancelPlan,
  updateCard,
  createPaymentIntent,
} from "../../actions/stripe";

// Initialize Stripe with your public key
const stripePromise = loadStripe("pk_test_Khx7hkeU1SvJXF80fxthA55g");

const BillingSettings = ({
  cancelPlan,
  createPaymentIntent,
  intent,
  setAlert,
  updateCard,
  user,
}) => {
  const navigate = useNavigate();

  const filterPlanById = (planId, plansList) => {
    return plansList.find((plan) => plan.plan === planId) || null;
  };

  const [plan, setPlan] = useState(null);
  useEffect(() => {
    if (user.plan) {
      const userPlan = filterPlanById(user.plan, plans);
      setPlan(userPlan);
    }
  }, [user.plan]);

  useEffect(() => {
    if (plan) {
      createPaymentIntent(plan.price * 100 || 1500);
    }
  }, [createPaymentIntent, plan]);

  const [secret, setSecret] = useState({ clientSecret: "" });
  useEffect(() => {
    if (intent) {
      setSecret(intent);
    }
  }, [intent]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleCancelRequest = () => {
    cancelPlan();
    setIsDeleteModalOpen(false);
  };

  const [updateBillingModal, setUpdateBillingModal] = useState(false);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Billing Information</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {plan ? (
          <>
            {user.subscription && (
              <>
                <div id="current-info">
                  <div className="mt-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-300 dark:border-gray-700">
                    <div className="flex items-center justify-between">
                      <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-2">
                        Current Billing Information
                      </h4>
                    </div>
                    {user.subscriptionInfo ? (
                      <div className="space-y-2">
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          <strong>Card Brand:</strong>{" "}
                          {user.subscriptionInfo.brand.toUpperCase()}
                        </p>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          <strong>Last 4 Digits:</strong> ••••{" "}
                          {user.subscriptionInfo.last4}
                        </p>
                        {/* <p className="text-sm text-gray-600 dark:text-gray-400">
                      <strong>Card Type:</strong>{" "}
                      {user.subscriptionInfo.funding.charAt(0).toUpperCase() +
                        user.subscriptionInfo.funding.slice(1)}{" "}
                      Card
                    </p> */}
                      </div>
                    ) : (
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        No payment method on file.
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="mt-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-300 dark:border-gray-700">
              <CreditCardPayment
                secret={secret.clientSecret}
                setAlert={setAlert}
                user={user}
                updateCard={updateCard}
              />
            </div>
            {/* <hr className="my-5" /> */}
            <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-300 dark:border-gray-700">
              {/* Plan Details */}
              <div>
                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                  {plan.name || "Current Plan"}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  ${plan.price} {plan.time}
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Includes {plan.codes} codes
                </p>
                {plan.code > 5 ? (
                  <p className="mt-3 text-sm text-gray-600 dark:text-gray-400">
                    To downgrade your plan, delete codes to have less than{" "}
                    {plan.minCodes}. It will ask you to upgrade after{" "}
                    {plan.codes} codes.
                  </p>
                ) : (
                  <p className="mt-3 text-sm text-gray-600 dark:text-gray-400">
                    Your plan will ask you to upgrade if you add more than{" "}
                    {plan.codes} codes.
                  </p>
                )}
              </div>
              {/* Plan Badge */}
              <div className="bg-indigo-500 text-white text-xs font-bold py-1 px-3 rounded-full">
                Current Plan
              </div>
            </div>

            {/* Buttons Section */}
            <div className="flex justify-between items-center">
              <Dialog
                open={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
              >
                <DialogTrigger asChild>
                  <Button variant="destructive" className="w-[180px]">
                    <Ban className="mr-2 h-4 w-4" /> Cancel Plan
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Cancel Plan</DialogTitle>
                    <DialogDescription>
                      Are you sure you want to cancel? We will release your
                      phone number and you won't be able to use it anymore.
                    </DialogDescription>
                  </DialogHeader>
                  <DialogFooter>
                    <Button
                      variant="outline"
                      onClick={() => setIsDeleteModalOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="destructive" onClick={handleCancelRequest}>
                      Delete
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              {/* <Button className="w-[180px]" variant="primary">
                        <CreditCardIcon className="mr-2 h-4 w-4" />
                        Upgrade Plan
                      </Button> */}
            </div>
          </>
        ) : (
          <Card>
            <CardHeader>
              <CardTitle>No Active Plan</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                You haven’t subscribed to a plan yet. Signing up for a plan
                allows you to track leads, manage calls, and unlock all features
                of SimpleSignCalls.
              </p>
              <Button
                onClick={() => navigate("/billing")}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white"
              >
                Explore Plans
              </Button>
            </CardContent>
          </Card>
        )}
      </CardContent>
    </Card>
  );
};

const PaymentForm = ({ user, setAlert, secret, updateCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setAlert("Stripe has not loaded yet.", "danger");
      setLoading(false);
      return;
    }

    try {
      if (!secret) {
        throw new Error("Client secret is missing.");
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: user.email,
        },
      });
      updateCard(paymentMethod.id, user.email, paymentMethod.card);

      if (error) {
        setAlert(
          error.message || "An error occurred during payment.",
          "danger"
        );
      }
    } catch (err) {
      setAlert(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#32325d",
              "::placeholder": {
                color: "#a0aec0",
              },
            },
            invalid: {
              color: "#fa755a",
            },
          },
        }}
      />
      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:opacity-50"
        disabled={!stripe || loading || !secret}
      >
        {loading ? (
          <>
            <Loader2 className="mx-auto animate-spin" />
          </>
        ) : (
          "Update Billing"
        )}
      </button>
    </form>
  );
};

// Wrapper component for Stripe Elements
const CreditCardPayment = ({ user, secret, setAlert, updateCard }) => (
  <div className=" bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 p-4">
    <Elements stripe={stripePromise}>
      <PaymentForm
        user={user}
        // handlePayment={handlePayment}
        secret={secret}
        setAlert={setAlert}
        updateCard={updateCard}
        // createSubscription={createSubscription}
        // letBill={letBill}
        // createPaymentIntent={createPaymentIntent}
      />
    </Elements>
  </div>
);

BillingSettings.propTypes = {
  cancelPlan: PropTypes.func.isRequired,
  createPaymentIntent: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  intent: state.stripe.intent,
});

export default connect(mapStateToProps, {
  cancelPlan,
  updateCard,
  createPaymentIntent,
  setAlert,
})(BillingSettings);
